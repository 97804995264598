import { InitAppForPageFn } from '@wix/yoshi-flow-editor';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServices,
  flowAPI,
) => {
  return {};
};
